// Custom styles
@import 'colors';
//@import 'layout';
@import 'custom';

body {
  background-color: #fff;
}

.hugeModal > .modal-dialog {
  max-width: 90% !important;
  width: 90% !important;
}

.mediumModal > .modal-dialog {
  max-width: 50% !important;
  width: 50% !important;
}

.modal-content {
  border-radius: 0;
}

.footer {
  width: 100%;
  padding: 20px;
  margin-top: 150px;
  background-color: #232525;
  color: #fff;

  //position:fixed;
  //bottom:0;
}

a,
a:visited {
  color: #32306f;
}

.btn.btn-ricerca {
  color: #fff !important;
  margin-top: 30px;
}

a,
.btn.btn-outline-primary {
  color: #32306f;
  border-color: #32306f;
  margin-left: 5px;
}

.btn.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #32306f;
  color: #fff;
}

.card, .breadcrumb {
  margin-left: 15px;
  margin-right: 15px;
}

.hide {
  display: none;
}
